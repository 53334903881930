import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import './layout.css'

import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: 'Fanwood Text';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Fanwood Text'), local('FanwoodText-Regular'),
    url(https://fonts.gstatic.com/s/fanwoodtext/v7/3XFtErwl05Ad_vSCF6Fq7xX2QtzZ9v5Cbw.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Quattrocento';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Quattrocento'),
    url(https://fonts.gstatic.com/s/quattrocento/v9/OZpEg_xvsDZQL_LKIF7q4jP3w2j6XYvVuQ.woff2)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

body {
  background: #f5f5f5;
  box-sizing: border-box;
  font: 400 1em / 1.5em 'Fanwood Text', 'Sans-Serif';
  color: #45484d;

  letter-spacing: 0.01em;

  /* Reset AMPs delay in showing content */
  -webkit-animation: -amp-start 0.01s steps(1, end) 0s 1 normal both;
  -moz-animation: -amp-start 0.01s steps(1, end) 0s 1 normal both;
  -ms-animation: -amp-start 0.01s steps(1, end) 0s 1 normal both;
  animation: -amp-start 0.01s steps(1, end) 0s 1 normal both;
}

body:before {
  border: 20px solid #fff;
  content: '';
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

body:after {
  content: '';
  position: fixed;
  z-index: 120;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  pointer-events: none;
  box-shadow: 0 6px 16px rgba(0,0,0,0.15);
}

@media screen and (min-width: 650px) {
  body:before {
    border: 40px solid #fff;
  }

  body:after {
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px;
  }
}

* {
  box-sizing: border-box;
}

p > a {
  color: #000;
  text-decoration: underline;
  text-underline-position: under;
}

header {
  top: 20px;
  padding: 3rem;
  z-index: 10;
  display: flex;
  align-items: center;
  flex-direction: column;
  vertical-align: middle;
  justify-content: center;
  background: #ee6d6d;
  color: #000;
  letter-spacing: .02em;
  font-kerning: normal;
  font-feature-settings: 'kern' 1,'kern';
  text-rendering: optimizeLegibility;
  position: fixed;

  @media screen and (min-width: 650px) {
    top: 40px;
    bottom: 40px;
    right: 40px;
    left: 40px;
  }
}
`

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Sample' },
            { name: 'keywords', content: 'sample, something' },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <GlobalStyle />
        <div>{children}</div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
